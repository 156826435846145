import AppUrlListener from '@components/appUrlListener/AppUrlListener'
import { Layout } from '@components/layout/Layout'
import { LayoutProvider } from '@components/layout/contexts/Layout.context'
import { SwayError } from '@components/swayStates/SwayError'
import { LocationHistoryProvider } from '@context/locationHistoryContext'
import { PushNotificationsContextProvider } from '@context/pushNotifications'
import { WalletContextProvider } from '@context/walletContext'
import { useAuth } from '@hooks/useAuth'
import { AppShell, Center } from '@mantine/core'
import { VendorAnalyticsRoutes } from '@pages/analytics/VendorAnalytics.routes'
import { AuthRoutes } from '@pages/auth/Auth.routes'
import { AlreadyRegistered } from '@pages/auth/register/AlreadyRegistered.page'
import { VendorBroadcastRoutes } from '@pages/broadcasts/Broadcasts.routes'
import { EarnRoutes } from '@pages/earn/Earn.routes'
import { FeedRoutes } from '@pages/feed/Feed.routes'
import {
  ProfileRoutes,
  PublicProfileRoutes,
} from '@pages/profile/Profile.routes'
import { VendorQRCodesRoutes } from '@pages/qrCodes/QRCodes.routes'
import {
  MemberRedemptionsRoutes,
  PublicRedemptionsRoutes,
  VendorRedemptionsRoutes,
} from '@pages/redemptions/Redemptions.routes'
import {
  MemberReferralsRoutes,
  VendorReferralsRoutes,
} from '@pages/referrals/Referrals.routes'
import { SettingsRoutes } from '@pages/settings/Settings.routes'
import { VendorDashboardRoutes } from '@pages/vendor'
import * as Sentry from '@sentry/react'
import { useMemo } from 'react'
import {
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
} from 'react-router-dom'
import { AppProviders } from '../AppContextProviders'
import { CleanupRoutes } from './Cleanup.routes'
import { RootRedirect } from './RootRedirect'
import { RequireAuth } from './guards/RequireAuth'
import { RequireToS } from './guards/RequireToS'

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

// ? This is a simple route change tracker that logs the route change to the console for debugging
// ? Uncomment and add to the AppRouterProviders component to enable
// function RouteChangeTracker() {
//   const location = useLocation()

//   useEffect(() => {
//     console.info('Route changed to:', location.pathname)
//   }, [location])

//   return null
// }

const AppRouterProviders = ({ children }: React.PropsWithChildren) => {
  return (
    <AppShell>
      <PushNotificationsContextProvider>
        <AppUrlListener />
        <ScrollRestoration />
        <LocationHistoryProvider>{children}</LocationHistoryProvider>
      </PushNotificationsContextProvider>
    </AppShell>
  )
}

export const AppRouter = () => {
  const { currentUser } = useAuth()
  const router = useMemo(() => {
    const unAuthenticatedRoutes = currentUser
      ? []
      : [...PublicProfileRoutes, ...PublicRedemptionsRoutes]

    const routes = [
      {
        path: '',
        element: (
          <AppRouterProviders>
            <LayoutProvider>
              <Layout>
                <Outlet />
              </Layout>
            </LayoutProvider>
          </AppRouterProviders>
        ),
        children: [
          {
            path: '',
            element: <RootRedirect />,
          },
          // * Unauthenticated Routes
          ...AuthRoutes,
          ...unAuthenticatedRoutes,
          // * Authenticated Routes
          {
            path: '',
            element: (
              <RequireAuth>
                <WalletContextProvider>
                  <RequireToS />
                  <Sentry.ErrorBoundary
                    fallback={SwayError}
                    beforeCapture={(scope) => {
                      scope.setTag('location', 'AuthenticatedRoutes')
                    }}
                  >
                    <Outlet />
                  </Sentry.ErrorBoundary>
                </WalletContextProvider>
              </RequireAuth>
            ),
            children: [
              {
                path: 'users/reset_password',
                async lazy() {
                  const { ResetPasswordPage } = await import(
                    '../pages/auth/resetPassword/ResetPassword.page'
                  )
                  return {
                    Component: ResetPasswordPage,
                  }
                },
                children: [
                  {
                    path: ':token',
                    async lazy() {
                      const { ResetPasswordPage } = await import(
                        '../pages/auth/resetPassword/ResetPassword.page'
                      )
                      return {
                        Component: ResetPasswordPage,
                      }
                    },
                  },
                ],
              },
              {
                path: 'confirm',
                async lazy() {
                  const { ConfirmAccountPage } = await import(
                    './../pages/auth/ConfirmAccount.page'
                  )
                  return {
                    Component: ConfirmAccountPage,
                  }
                },
              },
              {
                path: 'token-auth',
                async lazy() {
                  const { TokenAuth } = await import(
                    '../pages/auth/TokenAuth.page'
                  )
                  return {
                    Component: TokenAuth,
                  }
                },
              },
              ...FeedRoutes,
              ...MemberReferralsRoutes,
              ...MemberRedemptionsRoutes,
              ...ProfileRoutes,
              ...EarnRoutes,
              ...SettingsRoutes,
              // * ************ Vendor Only **************
              {
                path: 'vendor',
                children: [
                  ...VendorDashboardRoutes,
                  ...VendorAnalyticsRoutes,
                  ...VendorRedemptionsRoutes,
                  ...VendorBroadcastRoutes,
                  ...VendorQRCodesRoutes,
                  ...VendorReferralsRoutes,
                ],
              },
              {
                path: 'register',
                element: (
                  <AppShell.Main>
                    <AlreadyRegistered />
                  </AppShell.Main>
                ),
              },
              {
                path: '',
                async lazy() {
                  const { RedirectPage } = await import(
                    './../pages/Redirect.page'
                  )
                  return {
                    element: <RedirectPage route="/" />,
                  }
                },
              },
              ...CleanupRoutes,
            ],
          },
        ],
        // * 404 and redirect to "/" if the user attempts to go to a page that doesn't exist
      },
      ...CleanupRoutes,
    ]

    return sentryCreateBrowserRouter(routes)
  }, [currentUser])

  return (
    <Sentry.ErrorBoundary
      fallback={(errorData) => (
        <AppShell.Main>
          <Center>
            You've landed into a location that you should not be. This is a
            significant problem. If this problem persists, please hard close the
            app or restart your browser. If it happens again, please reach out
            to support.
          </Center>
          <SwayError {...errorData} />
        </AppShell.Main>
      )}
      beforeCapture={(scope) => {
        scope.setTag('location', 'AppRouter')
        scope.setTag('level', 'HIGH')
      }}
    >
      <AppProviders>
        <RouterProvider router={router} />
      </AppProviders>
    </Sentry.ErrorBoundary>
  )
}
