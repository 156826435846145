import { SwayError } from '@components/swayStates/SwayError'
import * as Sentry from '@sentry/react'
import { LoginPage } from './login'

export const AuthRoutes = [
  {
    path: 'login',
    element: (
      <Sentry.ErrorBoundary
        fallback={SwayError}
        beforeCapture={(scope) => {
          scope.setTag('location', 'LoginPage')
        }}
      >
        <LoginPage />
      </Sentry.ErrorBoundary>
    ),
  },
  {
    path: 'register',
    async lazy() {
      const { RegisterMemberPage } = await import(
        './register/RegisterMember.page'
      )

      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'RegisterMemberPage')
            }}
          >
            <RegisterMemberPage />
          </Sentry.ErrorBoundary>
        ),
      }
    },
  },
  {
    path: 'register/vendor/:displayName',
    async lazy() {
      const { RegisterMemberPage } = await import(
        './register/RegisterMember.page'
      )

      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'RegisterMemberPage.displayName')
            }}
          >
            <RegisterMemberPage />
          </Sentry.ErrorBoundary>
        ),
      }
    },
  },
  {
    path: 'register/vendor',
    async lazy() {
      const { RegisterVendorPage } = await import(
        './register/RegisterVendor.page'
      )

      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'RegisterVendorPage')
            }}
          >
            <RegisterVendorPage />
          </Sentry.ErrorBoundary>
        ),
      }
    },
  },
  {
    path: 'register/redemption/:redemptionId',
    async lazy() {
      const { RegisterMemberPage } = await import(
        './register/RegisterMember.page'
      )

      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'RegisterMemberPage.redemptionId')
            }}
          >
            <RegisterMemberPage />
          </Sentry.ErrorBoundary>
        ),
      }
    },
  },
  {
    path: 'confirm',
    async lazy() {
      const { ConfirmAccountPage } = await import('./ConfirmAccount.page')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'ConfirmAccountPage')
            }}
          >
            <ConfirmAccountPage />
          </Sentry.ErrorBoundary>
        ),
      }
    },
  },
  {
    path: 'token-auth',
    async lazy() {
      const { TokenAuth } = await import('./TokenAuth.page')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'TokenAuth')
            }}
          >
            <TokenAuth />
          </Sentry.ErrorBoundary>
        ),
      }
    },
  },
  {
    path: 'users/reset_password',
    async lazy() {
      const { ResetPasswordPage } = await import(
        './resetPassword/ResetPassword.page'
      )
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'ResetPasswordPage')
            }}
          >
            <ResetPasswordPage />
          </Sentry.ErrorBoundary>
        ),
      }
    },
    children: [
      {
        path: ':token',
        async lazy() {
          const { ResetPasswordPage } = await import(
            './resetPassword/ResetPassword.page'
          )
          return {
            element: (
              <Sentry.ErrorBoundary
                fallback={SwayError}
                beforeCapture={(scope) => {
                  scope.setTag('location', 'ResetPasswordPage.token')
                }}
              >
                <ResetPasswordPage />
              </Sentry.ErrorBoundary>
            ),
          }
        },
      },
    ],
  },
  {
    path: 'thankYou/:email',
    async lazy() {
      const { RegisterEmailSentPage } = await import('./RegisterEmailSent.page')
      return {
        element: (
          <Sentry.ErrorBoundary
            fallback={SwayError}
            beforeCapture={(scope) => {
              scope.setTag('location', 'RegisterEmailSentPage')
            }}
          >
            <RegisterEmailSentPage />
          </Sentry.ErrorBoundary>
        ),
      }
    },
  },
]
