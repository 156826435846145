import { UseFormReturnType } from "@mantine/form"
import { BroadcastFormValues } from "../BroadcastContext"
import { Button, Collapse, Select } from "@mantine/core"
import { InputLabelSectionHeader } from "@components/InputLabelSectionHeader/InputLabelSectionHeader"
import { Card } from "@mantine/core"
import { Stack } from "@mantine/core"
import { EditFormMultipleChoiceOptions } from "../EditFormMultipleChoiceOptions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { MessageType } from "@graphql"
import { faPlus } from "@fortawesome/free-solid-svg-icons"

type BroadcastMessageTypeProps = {
  form: UseFormReturnType<BroadcastFormValues, (values: BroadcastFormValues) => BroadcastFormValues>,
  isMultipleChoice: boolean,
  messageTypeSelectValue: string | null | undefined
  setIsMultipleChoice: React.Dispatch<React.SetStateAction<boolean>>,
  setMessageTypeSelectValue: React.Dispatch<React.SetStateAction<string | null | undefined>>
  disableMessageTypeSelector?: boolean | undefined
}

export const BroadcastMessageType = ({ form, isMultipleChoice, messageTypeSelectValue, setIsMultipleChoice, setMessageTypeSelectValue, disableMessageTypeSelector }: BroadcastMessageTypeProps) => {
  const handleMessageTypeChange = (e: string | null) => {
    switch (e) {
      case 'View':
        {
          form.setFieldValue('messageTemplate.type', MessageType.View)
          form.setFieldValue('messageTemplate.options', [])
          setIsMultipleChoice(false)
        }
        break
      case 'Reply':
        {
          form.setFieldValue('messageTemplate.type', MessageType.Reply)
          form.setFieldValue('messageTemplate.options', [])
          setIsMultipleChoice(false)
        }
        break
      case 'Multiple-choice':
        {
          form.setFieldValue('messageTemplate.type', MessageType.Reply)
          form.setFieldValue('messageTemplate.options', [{ text: '' }])
          setIsMultipleChoice(true)
        }
        break
      default: {
        form.setFieldValue('messageTemplate.type', null)
        setIsMultipleChoice(false)
      }
    }
  }

  const handleAddOption = () => {
    form.insertListItem('messageTemplate.options', {
      text: '',
    })
  }

  const handleRemoveOption = (index: number) => {
    form.removeListItem('messageTemplate.options', index)
  }

  return (
    <>
      <Select
        data={['View', 'Reply', 'Multiple-choice']}
        label={
          <InputLabelSectionHeader>Message Type</InputLabelSectionHeader>
        }
        placeholder="Select message type"
        required
        value={messageTypeSelectValue}
        disabled={disableMessageTypeSelector}
        onChange={(e) => {
          handleMessageTypeChange(e)
          setMessageTypeSelectValue(e)
        }}
      />
      <Collapse in={isMultipleChoice}>
        <Card withBorder>
          <Stack>
            <EditFormMultipleChoiceOptions
              handleRemoveOption={handleRemoveOption}
              formOptionsField="messageTemplate.options"
            />
            <Button
              variant="Outline"
              leftSection={<FontAwesomeIcon icon={faPlus} />}
              onClick={handleAddOption}
            >
              Add Response
            </Button>
          </Stack>
        </Card>
      </Collapse>
    </>
  )
}