import { Position } from '@capacitor/geolocation'
import {
  MediaOption,
  PageFilterOp,
  PageFilterValues,
  PageOrderDirection,
  RedemptionFiltersInput,
  RedemptionOrderByInput,
  RedemptionQueryAttributes,
} from '@graphql'
import { useGetLocation } from '@hooks/useGetLocation'
import { ComboboxItem } from '@mantine/core'
import { UseFormReturnType, useForm } from '@mantine/form'
import useRedemptionFiltersStore from '@stores/useRedemptionFiltersStore'
import { milesToKm } from '@util/utils'
import { createContext, useState } from 'react'
import {
  FILTERS,
  MAX_FILTER_PRICE,
  MIN_FILTER_PRICE,
  SORT_BY,
  SWAYCASH_CENT_CONVERSION_FACTOR,
} from './redemptionFilters.constants'

type BuildQueryArguments = (
  position?: Position | null,
  imgixOpts?: MediaOption[],
  defaultLimit?: number
) => {
  query: {
    limit?: number
    filters?: RedemptionFiltersInput[]
    orderBy?: RedemptionOrderByInput[]
    offset: number
  }
  currentLocation: {
    latitude: number
    longitude: number
  } | null
  imgixOpts?: MediaOption[]
}

export type FilterFormValues = {
  sortBy?: string | boolean
  priceBetween?: [number, number]
  vendorsId?: string[]
  useCurrentLocation?: boolean
  radiusKm?: number
  onlyDonations?: boolean
  excludeExpired?: boolean
}

const sortValues = {
  [SORT_BY.PRICE_HIGH_TO_LOW]: {
    attribute: RedemptionQueryAttributes.Price,
    direction: PageOrderDirection.Desc,
  },
  [SORT_BY.PRICE_LOW_TO_HIGH]: {
    attribute: RedemptionQueryAttributes.Price,
    direction: PageOrderDirection.Asc,
  },
  [SORT_BY.MOST_POPULAR]: {
    attribute: RedemptionQueryAttributes.RedeemCount,
    direction: PageOrderDirection.Desc,
  },
  [SORT_BY.MOST_VIEWED]: {
    attribute: RedemptionQueryAttributes.ViewCount,
    direction: PageOrderDirection.Desc,
  },
  [SORT_BY.CLOSEST]: {
    attribute: RedemptionQueryAttributes.RadiusKm,
    direction: PageOrderDirection.Asc,
  },
}

const defaultFilters: FilterFormValues = {
  sortBy: undefined,
  priceBetween: [MIN_FILTER_PRICE, MAX_FILTER_PRICE],
  vendorsId: [],
  useCurrentLocation: false,
  radiusKm: 100,
  onlyDonations: false,
  excludeExpired: false,
}

const generateFlopEntry = (
  key: string,
  value: FilterFormValues[keyof FilterFormValues]
): {
  orderByInput: RedemptionOrderByInput[]
  filtersInput: RedemptionFiltersInput[]
} => {
  const orderByInput: RedemptionOrderByInput[] = []
  const filtersInput: RedemptionFiltersInput[] = []

  switch (key) {
    case FILTERS.SORT_BY: {
      const sortByValue = value as string
      if (sortByValue) {
        sortByValue && orderByInput.push(sortValues[sortByValue as string])
      }
      break
    }
    case FILTERS.PRICE_BETWEEN: {
      const priceBetween = value as [number, number]
      if (priceBetween) {
        const [min, max] = priceBetween

        const minFilter =
          min !== MIN_FILTER_PRICE &&
          generateFlopFilter(
            RedemptionQueryAttributes.Price,
            PageFilterOp.GtEq,
            { integer: min * SWAYCASH_CENT_CONVERSION_FACTOR }
          )

        const maxFilter =
          max !== MAX_FILTER_PRICE &&
          generateFlopFilter(
            RedemptionQueryAttributes.Price,
            PageFilterOp.LtEq,
            { integer: max * SWAYCASH_CENT_CONVERSION_FACTOR }
          )

        minFilter && filtersInput.push(minFilter)
        maxFilter && filtersInput.push(maxFilter)
      }
      break
    }

    case FILTERS.VENDORS_ID: {
      const filterByValue = value as string[]

      filterByValue.length > 0 &&
        filtersInput.push({
          attribute: RedemptionQueryAttributes.CommunityId,
          op: PageFilterOp.In,
          value: { strings: filterByValue },
        })
      break
    }

    case FILTERS.FULL_TEXT: {
      const fullTextValue = value as string
      if (fullTextValue) {
        filtersInput.push(
          generateFlopFilter(
            RedemptionQueryAttributes.FullText,
            PageFilterOp.Contains,
            { string: fullTextValue }
          )
        )
      }
      break
    }

    case FILTERS.RADIUS_KM: {
      const radiusValueInMiles = value === 100 ? 100 : (value as number) / 10
      const radiusValueInKm = milesToKm(radiusValueInMiles)
      if (radiusValueInKm) {
        filtersInput.push(
          generateFlopFilter(
            RedemptionQueryAttributes.RadiusKm,
            PageFilterOp.LtEq,
            { float: radiusValueInKm }
          )
        )
      }

      break
    }

    case FILTERS.ONLY_DONATIONS: {
      const onlyNonProfit = value as boolean
      if (onlyNonProfit) {
        filtersInput.push(
          generateFlopFilter(
            RedemptionQueryAttributes.IsNonProfit,
            PageFilterOp.Eq,
            { boolean: true }
          )
        )
      }
      break
    }

    case FILTERS.EXCLUDE_EXPIRED: {
      filtersInput.push(
        generateFlopFilter(
          RedemptionQueryAttributes.GlobalLimitReached,
          PageFilterOp.Eq,
          { boolean: false }
        )
      )
      filtersInput.push(
        generateFlopFilter(
          RedemptionQueryAttributes.ValidDateRange,
          PageFilterOp.Contains,
          { string: new Date().toISOString() }
        )
      )
      break
    }

    default: {
      break
    }
  }

  return { orderByInput: orderByInput, filtersInput: filtersInput }
}

// Generates a filter object for the flop query
const generateFlopFilter = (
  attribute: RedemptionQueryAttributes,
  op: PageFilterOp,
  value: PageFilterValues
): RedemptionFiltersInput => {
  const result = {
    attribute: attribute,
    op: op,
    value: value,
  }

  return result
}

export interface RedemptionFiltersContextType {
  currentFilters: FilterFormValues
  setFilters: React.Dispatch<React.SetStateAction<FilterFormValues>>
  currentSelectedVendorsForFilter: ComboboxItem[]
  setSelectedVendorsForFilter: React.Dispatch<
    React.SetStateAction<ComboboxItem[]>
  >
  generateFlopEntry: (
    key: string,
    value: FilterFormValues[keyof FilterFormValues]
  ) => {
    orderByInput: RedemptionOrderByInput[]
    filtersInput: RedemptionFiltersInput[]
  }
  filtersForm: UseFormReturnType<FilterFormValues>
  defaultFilters: FilterFormValues
  buildQueryArguments: BuildQueryArguments
}

export const RedemptionFiltersContext =
  createContext<RedemptionFiltersContextType>({
    currentFilters: defaultFilters,
    setFilters: () => null,
    currentSelectedVendorsForFilter: [],
    setSelectedVendorsForFilter: () => null,
    generateFlopEntry: () => ({ orderByInput: [], filtersInput: [] }),
    filtersForm: {} as UseFormReturnType<FilterFormValues>,
    defaultFilters,
    buildQueryArguments: () => ({
      query: { offset: 0 },
      currentLocation: null,
    }),
  })

export function RedemptionFiltersProvider({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const [currentSelectedVendorsForFilter, setSelectedVendorsForFilter] =
    useState<ComboboxItem[]>([])

  const { storedFiltersFormValues, setStoredFiltersFormValues } =
    useRedemptionFiltersStore()
  // If we have filters in our store, then we use those rather than the default ones.
  const initialFilters = storedFiltersFormValues
    ? storedFiltersFormValues
    : defaultFilters

  const [currentFilters, setFilters] =
    useState<FilterFormValues>(initialFilters)

  const { permissions } = useGetLocation()

  const filtersForm = useForm<FilterFormValues>({
    initialValues: {
      ...initialFilters,
      useCurrentLocation: permissions?.location === 'granted',
    },
    onValuesChange: (values) => setStoredFiltersFormValues(values),
  })

  // Based on the current filters form values, returns a set of query arguments.
  const buildQueryArguments = (
    position?: Position | null,
    imgixOpts?: MediaOption[],
    defaultLimit?: number
  ) => {
    const filtersInput: RedemptionFiltersInput[] = []
    const orderByInput: RedemptionOrderByInput[] = []
    const formValues = filtersForm.values

    Object.keys(formValues).forEach((key) => {
      const value = formValues[key as keyof typeof formValues]
      const defaultValue = defaultFilters[key as keyof typeof formValues]

      if ((filtersForm.isDirty(key) || defaultValue === value) && value) {
        const {
          filtersInput: filtersInputValue,
          orderByInput: orderByInputValue,
        } = generateFlopEntry(key, value)

        filtersInput.push(...filtersInputValue)

        orderByInput.push(...orderByInputValue)
      }
    })
    const currentLocation = formValues.useCurrentLocation
      ? {
          latitude: position?.coords.latitude as number,
          longitude: position?.coords.longitude as number,
        }
      : null

    return {
      query: {
        limit: defaultLimit,
        ...(filtersInput.length && { filters: filtersInput }),
        ...(orderByInput.length && { orderBy: orderByInput }),
        offset: 0,
      },
      currentLocation,
      imgixOpts,
    }
  }

  return (
    <RedemptionFiltersContext.Provider
      value={{
        currentFilters,
        setFilters,
        currentSelectedVendorsForFilter,
        setSelectedVendorsForFilter,
        generateFlopEntry,
        filtersForm,
        defaultFilters,
        buildQueryArguments,
      }}
    >
      {children}
    </RedemptionFiltersContext.Provider>
  )
}
