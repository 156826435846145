import { LoadingOverlay } from '@mantine/core'

type SwayLoadingOverlayProps = {
  visible: boolean
  overlayProps?: object
  loaderProps?: object
  styleProps?: object
}

export const SwayLoadingOverlay = ({
  visible,
  overlayProps = {},
  loaderProps = {},
  styleProps = {},
}: SwayLoadingOverlayProps) => {
  return (
    <LoadingOverlay
      visible={visible}
      zIndex={50}
      overlayProps={{ radius: 'sm', blur: 2, ...overlayProps }}
      loaderProps={{ color: 'swurple', type: 'bars', top: 50, ...loaderProps }}
      style={{ alignItems: 'flex-start', ...styleProps }}
    />
  )
}
