import { useFeatureFlags } from '@context/index'
import { ProfileType } from '@graphql'
import { useAuth } from '@hooks/useAuth'
import { AppShell, Center, Loader, Text } from '@mantine/core'
import { Navigate } from 'react-router-dom'

/**
 * Based on the user's profile type and feature flags, redirect the user to:
 * - `/feed` if they are a community follower and the content feed is enabled
 * - `/conversations` if they are a vendor or community follower without content feed
 * - `/login` if they are not logged in
 */
export function RootRedirect() {
  const { currentUser } = useAuth()
  const { contentFeed, hasLoaded, vendorDashboard } = useFeatureFlags()

  if (!hasLoaded && currentUser) {
    return (
      <AppShell.Main>
        <Center
          style={{ width: '100%', height: '100%', flexDirection: 'column' }}
        >
          <Loader size="xl" type="bars" />
          <Text mt="md">Loading, please wait...</Text>
        </Center>
      </AppShell.Main>
    )
  }

  if (
    currentUser?.profileType === ProfileType.CommunityFollower &&
    contentFeed === true
  ) {
    return <Navigate to="/feed" />
  } else if (currentUser) {
    if (vendorDashboard) {
      return <Navigate to="/vendor/dashboard" />
    } else {
      return <Navigate to="/conversations" />
    }
  } else {
    return <Navigate to="/login" />
  }
}
