import { create } from 'zustand'
import { persist } from 'zustand/middleware'

type State = {
  lastSeenItemId: string | undefined
}

type Action = {
  updateLastSeenItemId: (newItemId: string | undefined) => void
}

type FeedStore = State & Action

const useFeedStore = create(
  persist<FeedStore>(
    (set, get) => ({
      lastSeenItemId: undefined,

      updateLastSeenItemId: (newItemId: string | undefined) => {
        // ! ⚡️ Short circuit if there is no new item id
        if (newItemId === undefined || newItemId === null) {
          return
        }

        // ! ⚡️ Short circuit if the new item id is the same as the last seen item id
        if (
          get().lastSeenItemId !== undefined &&
          newItemId === get().lastSeenItemId
        ) {
          return
        }

        // * 🌈 All good to go
        set({ lastSeenItemId: newItemId })
      },
    }),
    {
      name: 'feed-store',
    }
  )
)

export default useFeedStore
