import { ApolloError } from '@apollo/client'
import { useWalletBalanceQuery, useWalletEventsSubscription } from '@graphql'
import { useAuth } from '@hooks/useAuth'
import { createContext } from 'react'

export interface WalletContextType {
  wallet: any
  walletLoading: boolean
  walletError: ApolloError | undefined
  refetch: () => Promise<any>
}

export const WalletContext = createContext<WalletContextType>({
  wallet: null,
  walletLoading: false,
  walletError: undefined,
  refetch: () => Promise.resolve(),
})

export function WalletContextProvider({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const { currentUser } = useAuth()
  const {
    data: walletData,
    loading,
    error,
    refetch,
  } = useWalletBalanceQuery({
    onError: (err) => {
      console.error('[Wallet Context Error: ', err)
    },
    fetchPolicy: 'cache-and-network',
    skip: !currentUser,
  })
  useWalletEventsSubscription({
    onData() {
      refetch()
    },
  })

  return (
    <WalletContext.Provider
      value={{
        wallet: walletData?.wallet,
        walletLoading: loading,
        walletError: error,
        refetch,
      }}
    >
      {children}
    </WalletContext.Provider>
  )
}
