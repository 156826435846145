import { ApolloProvider } from '@apollo/client'
import useAppContext from '@context/appContext/useAppContext'
import { FeatureFlagContextProvider } from '@context/featureFlagContext'
import { useRefreshAuthToken } from '@hooks/useRefreshAuthToken'
import { useSwayApolloClient } from '@hooks/useSwayApolloClient'
import { useEffect } from 'react'
import { FIVE_MINUTES, ONE_MINUTE } from './constants'
import { CameraProvider } from './context'
import { NotificationSettingsContextProvider } from './pages/settings/screens/notificationSettings/context/NotificationSettings.context'

const AppContextProviders: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { backgroundTimestamp, currentAppState, deviceInfo } = useAppContext()

  // * Our Configuration for the Apollo Client. This is _NOT_ a context provider and will return a unique client
  // * everywhere it is used.
  const client = useSwayApolloClient()

  useEffect(
    function resetApolloStoreWhenFocusRegained() {
      if (backgroundTimestamp) {
        const now = Date.now()
        const diff = now - backgroundTimestamp

        if (deviceInfo?.platform === 'web' && diff > FIVE_MINUTES) {
          client?.resetStore()
        } else if (diff > ONE_MINUTE) {
          client?.resetStore()
        }
      }
      // ? currentAppState required to trigger the useEffect when the app is brought back to the foreground
    },
    [backgroundTimestamp, client, deviceInfo?.platform, currentAppState]
  )

  if (!client) {
    return null
  }

  return (
    <ApolloProvider client={client}>
      <FeatureFlagContextProvider>
        <NotificationSettingsContextProvider>
          <CameraProvider>{children}</CameraProvider>
        </NotificationSettingsContextProvider>
      </FeatureFlagContextProvider>
    </ApolloProvider>
  )
}

const AppProvidersWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  useRefreshAuthToken()

  return <>{children}</>
}

export const AppProviders: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <AppContextProviders>
      <AppProvidersWrapper>{children}</AppProvidersWrapper>
    </AppContextProviders>
  )
}
