import { Capacitor } from '@capacitor/core'
import { ScreenOrientation } from '@capacitor/screen-orientation'
import { SplashScreen } from '@capacitor/splash-screen'
import { StatusBar } from '@capacitor/status-bar'
import {
  AnalyticsProvider,
  AppProvider,
  NetworkProvider,
} from '@context/index.ts'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'

if (Capacitor.getPlatform() === 'android') {
  StatusBar.setOverlaysWebView({ overlay: true }).then(() => {
    console.info('setting overlay to true')
  })
}

// Set up mobile specific settings
if (
  Capacitor.getPlatform() === 'ios' ||
  Capacitor.getPlatform() === 'android'
) {
  ScreenOrientation.lock({ orientation: 'portrait' })
}

SplashScreen.hide()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <AppProvider>
      <NetworkProvider>
        <AnalyticsProvider>
          <App />
        </AnalyticsProvider>
      </NetworkProvider>
    </AppProvider>
  </React.StrictMode>
)
